import React, { ReactNode } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { isFunction, isObject, isString } from 'lodash-es';

import { PageSchema, PageSeo, SiteSchema } from './seo.model';
import { postToSeoOptions, toTwitter } from './seo-utils';

export interface SeoProps {
    meta?: ReactNode;
    page?: PageSeo;
    pageSchema?: PageSchema;
    siteSchema?: SiteSchema;
    processSchema?: (schema: string) => string;

    /**
     * This must be a React component that will render meta tags into the <head> element in the HTML
     *
     * @type {React.ComponentType<{ children: React.ReactNode }>}
     * @memberof SeoProps
     */
    MetaRenderElement: React.ComponentType<{ children: React.ReactNode }>;
}

// This component is primarily ported from the logic found in the react-headless-yoast repository:
// https://github.com/wjohnsto/react-headless-yoast/tree/main
// It handles SEO-related rendering for pages, utilizing meta, schema, and other related data for proper SEO management.
export function Seo({ meta, page, pageSchema, siteSchema, MetaRenderElement, processSchema }: SeoProps) {
    const seoOptions = postToSeoOptions(page || {}, pageSchema, siteSchema);

    const { title, description, canonical, locale, keywords, robots, og, twitter } = seoOptions;

    const { pageSchema: pageSchemaObj, siteSchema: siteSchemaObj } = seoOptions;

    let pageSchemaStr = isObject(pageSchemaObj) ? JSON.stringify(pageSchemaObj) : undefined;
    let siteSchemaStr = isObject(siteSchemaObj) ? JSON.stringify(siteSchemaObj) : undefined;

    let finalSiteSchemaObj = siteSchemaObj;

    if (isFunction(processSchema)) {
        if (isString(pageSchemaStr)) {
            try {
                pageSchemaStr = DOMPurify.sanitize(processSchema(pageSchemaStr));
            } catch (err) {
                console.error('Failed to parse sanitized page schema JSON:', err);
                pageSchemaStr = undefined;
            }
        }

        if (isString(siteSchemaStr)) {
            try {
                const processed = processSchema(siteSchemaStr);
                siteSchemaStr = DOMPurify.sanitize(processed);
                finalSiteSchemaObj = JSON.parse(siteSchemaStr) as SiteSchema;
            } catch (err) {
                console.error('Failed to parse sanitized site schema JSON:', err);
                finalSiteSchemaObj = undefined;
            }
        }
    }

    return (
        <MetaRenderElement>
            {meta}
            <title>{title}</title>
            <meta
                name="robots"
                content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, ${robots.index}, ${robots.follow}`}
            />
            {isString(canonical) && <link rel="canonical" href={canonical} />}
            {isString(description) && <meta name="description" content={description} />}
            {isString(keywords) && <meta name="keywords" content={keywords} />}
            <meta property="og:locale" content={locale} />
            <meta property="og:type" content={og?.type} />
            {isString(finalSiteSchemaObj?.siteName) && (
                <meta property="og:site_name" content={finalSiteSchemaObj?.siteName} />
            )}
            {isString(og?.title) && <meta property="og:title" content={og?.title} />}
            {isString(og?.description) && <meta property="og:description" content={og?.description} />}
            {isString(og?.author) && <meta property="og:author" content={og?.author} />}
            {isString(og?.url) && <meta property="og:url" content={og?.url} />}
            {isString(og?.publishedTime) && <meta property="article.published_time" content={og?.publishedTime} />}
            {isString(og?.modifiedTime) && <meta property="article.modified_time" content={og?.modifiedTime} />}
            {isString(og?.image?.sourceUrl) && (
                <>
                    <meta property="og:image" content={og?.image?.sourceUrl} />
                    {isString(og?.image?.altText) && <meta property="og:image:alt" content={og?.image?.altText} />}
                </>
            )}
            {isString(twitter?.title) && <meta name="twitter:title" content={twitter?.title} />}
            {isString(twitter?.description) && <meta name="twitter:description" content={twitter?.description} />}
            {isString(twitter?.image?.sourceUrl) && (
                <>
                    <meta name="twitter:image" content={twitter?.image?.sourceUrl} />
                    {isString(twitter?.image?.altText) && (
                        <meta name="twitter:image:alt" content={twitter?.image?.altText} />
                    )}
                </>
            )}
            {isString(twitter?.creator) && <meta name="twitter:creator" content={toTwitter(twitter?.creator)} />}
            {isString(pageSchemaStr) && (
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: pageSchemaStr }} />
            )}
            {isString(siteSchemaStr) && (
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: siteSchemaStr }} />
            )}
        </MetaRenderElement>
    );
}
