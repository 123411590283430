import { isObject, isString } from 'lodash-es';

import { PageSchema, PageSeo, SeoImage, SeoOptions, SiteSchema } from './seo.model';

export const postToSeoOptions = (
    page: PageSeo,
    pageSchema?: PageSchema | string,
    siteSchema?: SiteSchema | string,
): SeoOptions => {
    const { seo } = page;
    let pageSchemaObject: PageSchema | undefined;
    if (isString(pageSchema)) {
        try {
            pageSchemaObject = JSON.parse(pageSchema) as PageSchema;
        } catch (err) {
            console.error('Failed to parse pageSchema JSON:', err);
            pageSchemaObject = undefined;
        }
    } else {
        pageSchemaObject = pageSchema;
    }

    // Fallback: if no valid pageSchema was provided, try to parse schema from seo.schema.raw
    if (!isObject(pageSchemaObject) && isString(seo?.schema?.raw)) {
        try {
            pageSchemaObject = JSON.parse(seo?.schema?.raw ?? '') as PageSchema;
        } catch (err) {
            console.error('Failed to parse page schema JSON:', err);
            pageSchemaObject = undefined;
        }
    }

    let siteSchemaObject: SiteSchema | undefined;
    if (isString(siteSchema)) {
        try {
            siteSchemaObject = JSON.parse(siteSchema) as SiteSchema;
        } catch (err) {
            console.error('Failed to parse siteSchema JSON:', err);
            siteSchemaObject = undefined;
        }
    } else if (isObject(siteSchema)) {
        siteSchemaObject = siteSchema;
    } else {
        siteSchemaObject = undefined;
    }

    const title =
        page.title || seo?.title || seo?.opengraphTitle || seo?.twitterTitle || siteSchemaObject?.siteName || '';

    const description = seo?.metaDesc || seo?.opengraphDescription || seo?.twitterDescription || '';

    const canonical = seo?.canonical || seo?.opengraphUrl;
    const locale = page.locale || 'en_US';
    const cornerstone = seo?.cornerstone;
    const focusKeyword = seo?.focuskw;
    const keywords = seo?.metaKeywords;
    const readingTime = seo?.readingTime;

    const robotsIndex = seo?.metaRobotsNoindex === 'noindex' ? 'noindex' : 'index';
    const robotsFollow = seo?.metaRobotsNofollow === 'nofollow' ? 'nofollow' : 'follow';

    const image = {
        sourceUrl:
            seo?.opengraphImage?.sourceUrl || seo?.twitterImage?.sourceUrl || siteSchemaObject?.companyLogo?.sourceUrl,
        altText: seo?.opengraphImage?.altText || seo?.twitterImage?.altText || siteSchemaObject?.companyLogo?.altText,
        srcSet: seo?.opengraphImage?.srcSet || seo?.twitterImage?.srcSet || siteSchemaObject?.companyLogo?.srcSet,
    };

    return {
        title,
        description,
        locale,
        canonical,
        cornerstone,
        focusKeyword,
        keywords,
        pageSchema: pageSchemaObject,
        siteSchema: siteSchemaObject,
        readingTime,
        robots: {
            index: robotsIndex,
            follow: robotsFollow,
        },
        og: {
            title: seo?.opengraphTitle || title,
            description: seo?.opengraphDescription || description,
            image: (seo?.opengraphImage as SeoImage) || image,
            type: seo?.opengraphType || 'website',
            url: seo?.opengraphUrl || seo?.canonical,
            author: seo?.opengraphAuthor,
            publisher: seo?.opengraphPublisher,
            modifiedTime: seo?.opengraphModifiedTime,
            publishedTime: seo?.opengraphPublishedTime,
        },
        twitter: {
            title: seo?.twitterTitle || title,
            description: seo?.twitterDescription || description,
            image: (seo?.twitterImage as SeoImage) || image,
            creator: page?.author?.node?.seo?.social?.twitter,
        },
    };
};

export const toTwitter = (str?: unknown): string | undefined => {
    if (typeof str !== 'string') return undefined;

    const trimmed = str.trim();
    if (trimmed === '') return undefined;
    if (trimmed.startsWith('@')) return trimmed;

    return `@${trimmed}`;
};
